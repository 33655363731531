import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const succeedText = 'Searching for an opponent... Don\'t close this window'
    const failedText = 'The player is playing right now or another error'
    const [textModal, setTextModal] = useState(succeedText)
    const openModal = () => setModalOpen(true);
    const closeModal = (apiRequest=null) => {
        if(apiRequest){
            apiRequest()
        }
        setModalOpen(false)
    }
    const updateTextModal = () => {
        setTextModal(failedText)
    }

    return (
        <ModalContext.Provider value={{ isModalOpen, succeedText, updateTextModal, textModal, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    return useContext(ModalContext);
};