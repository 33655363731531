import styles from "./AddFriendButton.module.css";
import React from "react";
import {useModal} from "../../../hooks/SearchRandomModalContext";

export default function AddFriendButton(){
    const { openModal} = useModal();
    return (
        <div className={styles.add_friend_btn} onClick={() => openModal()}>
            Add friend
        </div>
    )
}