import AuthApiEnum from "../Enums/EnumAuthApi";

export const SIGN_IN_API_URL = process.env.REACT_APP_API_URL_AUTH + AuthApiEnum.LOGIN
export const LOGOUT_API_URL = process.env.REACT_APP_API_URL_AUTH + AuthApiEnum.LOGOUT
export const REFRESH_TOKEN_API_URL = process.env.REACT_APP_API_URL_AUTH + AuthApiEnum.REFRESH
export const SIGN_UP_API_URL = process.env.REACT_APP_API_URL_AUTH + AuthApiEnum.SIGN_UP
export const RESET_PASSWORD_API_URL = process.env.REACT_APP_API_URL_AUTH + AuthApiEnum.RESET
export const RESET_CONFIRMATION_PASSWORD_API_URL = process.env.REACT_APP_API_URL_AUTH + AuthApiEnum.RESET_CONFIRMATION
export const ACTIVATION_ACCOUNT_API_URL = process.env.REACT_APP_API_URL_AUTH + AuthApiEnum.ACTIVATE_USER

export const MIN_LENGTH_PASSWORD = 8
export const MAX_LENGTH_PASSWORD = 128

export const MIN_LENGTH_USERNAME = 3
export const MAX_LENGTH_USERNAME = 50

export const MIN_LENGTH_EMAIL = 6
export const MAX_LENGTH_EMAIL = 60

export const MIN_LOCAL_PART_EMAIL = 1
export const MAX_LOCAL_PART_EMAIL = 51

export const MIN_DOMAIN_PART_EMAIL = 1
export const MAX_DOMAIN_PART_EMAIL = 52