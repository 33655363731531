import React, { useEffect, useState } from "react";
import {getFriendLeaders} from "../../../api/main";
import { FRIENDSHIP_ROOM_WS_URL } from "../../../constants/main/sockets";
import RouteEnum from "../../../constants/Enums/EnumRoutes";
import { useNavigate } from "react-router-dom";
import styles from "./Statistics.module.css";
import {useUserContext} from "../../../hooks/useUser";
import useWebSearchOpponentSocket from "../../../hooks/useFindOpponentSocket";
import {useModal} from "../../../hooks/SearchRandomModalContext";
import InviteField from "../InviteItem/InviteField";
import RandomGameModal from "../RandomGameModal/RandomGameModal";
import FriendInvitationModal from "../FriendInvitationModal/FriendInvitationModal";
import FriendLeaderTrField from "../FriendLeaderTrField/FriendLeaderTrField";

export default function FriendLeaderStatistic() {
    const [gameRooms, setGameRooms] = useState({});
    const {isModalOpen} = useModal();
    const [friendInvitation, setFriendInvitation] = useState([])
    function updateFriendInvitation(data){
        if(Object.keys(data).length && !isModalOpen && !friendInvitation.some(obj => obj.inviter === data.inviter)){
            setFriendInvitation([...friendInvitation, data])
        } else if (!Object.keys(data).length) {
            setFriendInvitation(data)
        }
    }
    const {user} = useUserContext()
    const [friendLeaders, setFriendLeaders] = useState([]);
    const navigate = useNavigate();
    const wsFriendshipRoomUrl = FRIENDSHIP_ROOM_WS_URL;

    const changeGameRoom = (data) => {
        if(!data.agreement){
            const updatedInvitations = friendInvitation.filter(invitation => invitation.inviter !== data.inviter);
            setFriendInvitation(updatedInvitations)
        } else{
            setGameRooms(data)
        }
    }

    const updateGameRoom = (data) => {
        setGameRooms(data);
    }

    const getInvites = friendInvitation.map((invite, index) =>
        <InviteField invite={invite} updateGameRoom={changeGameRoom} key={index}/>
    )
    const trList = friendLeaders.map((friendData, index) =>
        <FriendLeaderTrField key={index} friendData={friendData} updateGameRoom={updateGameRoom}></FriendLeaderTrField>
    );

    const sendInvitation = (data) => {
        const my_username = user.username;
        const second_player = data.room['second_player'];
        const roomId = data.room['roomId']
        if (data.room['agreement'] && roomId) {
            const gameUrl = RouteEnum.GAME.replace(":id", roomId);
            navigate(gameUrl);
        }
        if (my_username === second_player) {
            updateFriendInvitation(data.room)
        }
    };
    useWebSearchOpponentSocket(wsFriendshipRoomUrl, friendLeaders, gameRooms, sendInvitation)

    useEffect(() => {
        getFriendLeaders()
            .then((response) => {
                setFriendLeaders(response.data);
            });
    }, []);

    return (
        <div className={styles.friends_statistics}>
            <table className={styles.game_statistics_table}>
                <thead>
                    <tr>
                        <th>Friend username</th>
                        <th>Points</th>
                        <th>Play with friend</th>
                    </tr>
                </thead>
                <tbody>{trList}</tbody>
            </table>
            <RandomGameModal friendInvitation={friendInvitation}></RandomGameModal>
            <FriendInvitationModal friendInvitation={friendInvitation} closeFriendInvitation={updateFriendInvitation}>
                {getInvites}
            </FriendInvitationModal>
        </div>
    );
}
