import ModalWindow from "../../GeneralComponents/ModalWindow/ModalWindow";
import React from "react";
import {deleteGameRoom} from "../../../api/main";
import {useModal} from "../../../hooks/SearchRandomModalContext";

export default function RandomGameModal({friendInvitation}){
    const { textModal, succeedText, isModalOpen, closeModal} = useModal();

    function deleteGameRoomRequest(){
        deleteGameRoom()
            .then(() => {
                closeModal()
            })
    }

    return (
        <>
            {isModalOpen && !friendInvitation.length &&
                <ModalWindow onClose={
                    () => closeModal(succeedText === textModal ? deleteGameRoomRequest : null)}>{textModal}
                </ModalWindow>
            }
        </>
    )
}