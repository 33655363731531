import styles from "../Statistics/Statistics.module.css";
import React from "react";
import {useUserContext} from "../../../hooks/useUser";

export default function FriendLeaderTrField({friendData, updateGameRoom}){
    const {user} = useUserContext()
    const gameRoomData = {
        'inviter': user.username,
        'second_player': friendData.username,
        'id': friendData['id'],
        'agreement': false
    };
    function handlePlayWithFriend() {
        updateGameRoom(gameRoomData);
    }
    return (
        <tr>
            <td>{friendData.username}</td>
            <td>{friendData.rating}</td>
            <td>
                <div className={styles.play_btn}
                     onClick={() => handlePlayWithFriend()}>play
                </div>
            </td>
        </tr>
    )
}