import React from "react";
import styles from "./InviteField.module.css"
import {createGameRoomVersusFriend} from "../../../api/main";

export default function InviteField({invite, updateGameRoom}){
    function acceptInvite() {
        invite.agreement = true
        const first_player = invite.inviter;
        const second_player = invite['second_player'];
        const gameRoomData = {
            "first_player": first_player,
            "second_player": second_player
        };
        createGameRoomVersusFriend(gameRoomData)
            .then((response) => {
                const data = response.data
                invite.roomId = data["game_room_id"]
                updateGameRoom(invite)
            })
    }

    function declineInvite() {
        updateGameRoom(invite)
    }

    return (
        <div className={styles.invite_field}>
            <div className={styles.inviter_field}>{invite.inviter}</div>
            <div>
                <button
                    className={styles.invite_decision_btn}
                    onClick={() => acceptInvite()}
                    style={{ marginRight: '0.4rem', backgroundColor: '#28a745'}}>
                    Accept
                </button>
                <button
                    className={styles.invite_decision_btn}
                    onClick={() => declineInvite()}
                    style={{ backgroundColor: '#dc3545'}}>
                    Decline
                </button>
            </div>
        </div>
    )
}