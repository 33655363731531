import styles from "../../assets/Auth.module.css";
import Input from "./Input/Input";
import Button from "./Button/Button";
import isValidationPassword, {getErrorPasswordData, isPasswordIdentity}
    from "../../utils/auth/formValidation/passwordValidation";
import {getErrorMessage, isEmpty} from "../../utils/auth/formValidation/generalValidtion";
import isValidationEmail, {getErrorEmailData} from "../../utils/auth/formValidation/emailValidation";
import {getErrorUsernameData, isValidationUsername} from "../../utils/auth/formValidation/usernameValidation";
import {placeholderText} from "../../constants/auth/placeholderConstants";
import {errorEmailValidationText, errorPasswordIdentity, errorPasswordValidationText, errorUsernameValidationText}
    from "../../constants/auth/errorValidationConstants";
import {getEmptyFieldError, renderErrorMessage} from "../../utils/auth/error";
import RouteEnum from "../../constants/Enums/EnumRoutes"
import useForm from "../../hooks/auth/ManageAuthForm";
import useError from "../../hooks/auth/ErrorApiAuth";
import {signUpUser} from "../../api/auth";

export default function FormSignUp(){
    const {formInfo, formInputStyle, isClickSubmitBtn, setIsClickSubmitBtn, setInfoSwitch, updateInputStyleApiError} = useForm()
    const {errorApiAuthList, setErrorApiAuthList, isEmptyField} = useError()
    const succeedCreatedUserText = 'Profile has been created. Check your inbox for activation link'
    const BODY_DATA = {
        username: formInfo.userName,
        email: formInfo.userEmail,
        password: formInfo.userPassword
    }

    function handleSignUpClick() {
        setIsClickSubmitBtn(true)
        if(
            isValidationUsername(formInfo.userName) &&
            isValidationEmail(formInfo.userEmail) &&
            isValidationPassword(formInfo.userPassword) &&
            isPasswordIdentity(formInfo.userPassword, formInfo.userConfirmPassword)
        ){
            signUpUser(BODY_DATA)
                .then(() => {
                    setErrorApiAuthList([succeedCreatedUserText])
                })
                .catch(error => {
                    const errorKey = Object.keys(error.response.data)[0]
                    setErrorApiAuthList(error.response.data[errorKey])
                    updateInputStyleApiError()
                })
        }
    }

    return(
        <div className={styles.main_content}>
            <Input
                placeholder={placeholderText.inputNameText}
                type={"text"}
                authInputStyle = {formInputStyle.userNameInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userName, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userName, formInputStyle.userNameInputStyle)}
                {formInfo.userName &&
                    getErrorMessage(getErrorUsernameData(formInfo), renderErrorMessage, errorUsernameValidationText)
                }
            </Input>
            <Input
                placeholder={placeholderText.inputEmailText}
                type={"email"}
                authInputStyle = {formInputStyle.userEmailInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userEmail, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userEmail, formInputStyle.userEmailInputStyle)}
                {formInfo.userEmail &&
                    getErrorMessage(getErrorEmailData(formInfo), renderErrorMessage, errorEmailValidationText)
                }
            </Input>
            <Input
                placeholder={placeholderText.inputPasswordText}
                type={"password"}
                authInputStyle = {formInputStyle.userPasswordInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userPassword, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userPassword, formInputStyle.userPasswordInputStyle)}
                {formInfo.userPassword &&
                    getErrorMessage(getErrorPasswordData(formInfo), renderErrorMessage, errorPasswordValidationText)
                }
            </Input>
            <Input
                placeholder={placeholderText.inputConfirmPasswordText}
                type={"password"}
                authInputStyle = {formInputStyle.userConfirmPasswordInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userConfirmPassword, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {(getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userConfirmPassword,
                    formInputStyle.userConfirmPasswordInputStyle)) ||
                    (isClickSubmitBtn && !isPasswordIdentity(formInfo.userPassword, formInfo.userConfirmPassword) &&
                    renderErrorMessage(errorPasswordIdentity)) ||
                    (!isEmpty(errorApiAuthList) && renderErrorMessage(errorApiAuthList))
                }
            </Input>
            <div className={styles.container_button}>
                <Button handleClick={handleSignUpClick}>continue</Button>
            </div>

            <div className={styles.container_redirect}>
                <span>Already have an account?</span>
                <a href={RouteEnum.SIGN_IN}>Sign in</a>
            </div>
        </div>
    )
}