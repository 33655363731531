import Enum from "./EnumTemplate";


export default class AuthApiEnum extends Enum {
    static SIGN_UP = "create/"
    static LOGIN = 'login/'
    static LOGOUT = 'logout/'
    static RESET = 'reset/'
    static RESET_CONFIRMATION = 'reset/confirmation/'
    static ACTIVATE_USER = 'activate/'
    static REFRESH = 'refresh/'
}