import styles from "./LogoutPanel.module.css"
import RouteEnum from "../../../constants/Enums/EnumRoutes";
import React from "react";
import {logoutUser} from "../../../api/auth";

export default function LogoutPanel(){
    const handleRedirectButtonClick = (url) => {
        const refreshToken = localStorage.getItem('refresh')
        const data = {
            'refresh': refreshToken
        }
        logoutUser(data)
            .then(() => {
                localStorage.removeItem("refresh")
                localStorage.removeItem("access")
                window.location.href = url
            })
            .catch(error => {
                const errorData = error.response.data
                console.log("errorData", errorData)
            })
    }

    return (
        <div className={styles.logout_panel}>
            <div className={styles.logout_btn}
                 onClick={() => handleRedirectButtonClick(RouteEnum.SIGN_IN)}
            >
                logout
            </div>
        </div>
    )
}