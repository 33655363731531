import styles from "../../assets/Auth.module.css";
import Input from "./Input/Input";
import Button from "./Button/Button";
import isValidationPassword, {getErrorPasswordData} from "../../utils/auth/formValidation/passwordValidation";
import {getErrorMessage, isEmpty} from "../../utils/auth/formValidation/generalValidtion";
import {getErrorUsernameData, isValidationUsername} from "../../utils/auth/formValidation/usernameValidation";
import {placeholderText} from "../../constants/auth/placeholderConstants";
import {
    errorPasswordValidationText,
    errorUsernameValidationText
} from "../../constants/auth/errorValidationConstants";
import useForm from "../../hooks/auth/ManageAuthForm";
import useError from "../../hooks/auth/ErrorApiAuth";
import RouteEnum from "../../constants/Enums/EnumRoutes";
import {getEmptyFieldError, renderErrorMessage} from "../../utils/auth/error";
import {signInUser} from "../../api/auth";
import {storeTokens} from "../../utils/auth/login";
import {useNavigate} from "react-router-dom";

export default function FormSignIn(){
    const {formInfo, formInputStyle, isClickSubmitBtn, setIsClickSubmitBtn, setInfoSwitch, updateInputStyleApiError} = useForm()
    const {errorApiAuthList, setErrorApiAuthList, isEmptyField} = useError()
    const navigate = useNavigate()

    const BODY_DATA = {
        username: formInfo.userName,
        password: formInfo.userPassword
    }

    function handleLoginClick() {
        setIsClickSubmitBtn(true)
        if(
            isValidationUsername(formInfo.userName) &&
            isValidationPassword(formInfo.userPassword)
        ){
            signInUser(BODY_DATA)
                .then((response) => {
                    const data = response.data
                    storeTokens(data)
                    navigate(RouteEnum.MAIN)
                })
                .catch(error => {
                    const errorKey = Object.keys(error.response.data)[0]
                    setErrorApiAuthList(error.response.data[errorKey])
                    updateInputStyleApiError()
                })
        }
    }

    return(
        <div className={styles.main_content}>
            <Input
                placeholder={placeholderText.inputNameText}
                type={"text"}
                authInputStyle = {formInputStyle.userNameInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userName, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {formInfo.userName &&
                    getErrorMessage(getErrorUsernameData(formInfo), renderErrorMessage, errorUsernameValidationText)
                }
                {getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userName, formInputStyle.userNameInputStyle)}

            </Input>

            <Input
                placeholder={placeholderText.inputPasswordText}
                type={"password"}
                authInputStyle = {formInputStyle.userPasswordInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userPassword, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {formInfo.userPassword &&
                    getErrorMessage(getErrorPasswordData(formInfo), renderErrorMessage, errorPasswordValidationText)
                }
                {getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userPassword, formInputStyle.userPasswordInputStyle)}
                {!isEmpty(errorApiAuthList) && renderErrorMessage(errorApiAuthList[0])}
            </Input>

            <div className={styles.container_button}>
                <Button handleClick={handleLoginClick}>log in</Button>
            </div>

            <div className={styles.container_redirect}>
                <span>Don't have an account?</span>
                 <a href={RouteEnum.SIGN_UP}>Sign up</a>
            </div>

            <div className={styles.container_redirect}>
                <a href={RouteEnum.FORGOT_PASSWORD}>Forgot your password?</a>
            </div>
        </div>
    )
}