import ModalWindow from "../../GeneralComponents/ModalWindow/ModalWindow";
import React from "react";

export default function FriendInvitationModal({friendInvitation, closeFriendInvitation, children}){
    const invitations = []
    return <>
         {friendInvitation.length &&
             <ModalWindow onClose={
                 () => closeFriendInvitation(invitations)}>{children}
             </ModalWindow>
         }
    </>
}