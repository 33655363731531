import Enum from "./EnumTemplate";


export default class MainApiEnum extends Enum {
    static GET_SUMMARY_FIELDS = "profile/get_summary_fields/"
    static GET_LEADERS = "profile/get_leaders/"
    static CREATE_GAME_ROOM = "games/create_new_room/"
    static CREATE_GAME_ROOM_VERSUS_FRIEND = "games/create_new_room_versus_friend/"
    static DElETE_GAME_ROOM = "games/delete_new_room/"
    static GET_FRIEND_LEADERS = 'friend/get_friends/'
}