import styles from "./PlayerDashboard.module.css";
import React, { useState  } from "react";
import useWebSearchOpponentSocket from "../../../hooks/useFindOpponentSocket";
import GameAction from "../GameAction/GameAction";
import {GAME_ROOM_WS_URL} from "../../../constants/main/sockets";
import PlayerProfileSummary from "../PlayerProfile/PlayerProfileSummary";
import {useNavigate} from "react-router-dom";
import RouteEnum from "../../../constants/Enums/EnumRoutes";
export default function PlayerDashboard() {
    const [roomData, setRoomData] = useState(null);
    const [room, setRoom] = useState([])
    const navigate = useNavigate()
    const wsGameRoomUrl = `${GAME_ROOM_WS_URL}`
    const handleRandomGameRoomUpdate = (roomData) => {
        setRoomData(roomData);
        setRoom([{"id": roomData.id}])
    };
    const handleRedirectToGame = (data) => {
        if (data.room['second_player'] !== null) {
            const gameUrl = RouteEnum.GAME.replace(":id", roomData.id)
            navigate(gameUrl)
        }
    };

    useWebSearchOpponentSocket(wsGameRoomUrl, room, roomData, handleRedirectToGame);

    return (
        <div className={styles.player_dashboard_card}>
            <PlayerProfileSummary></PlayerProfileSummary>
            <GameAction onDataAction={handleRandomGameRoomUpdate}></GameAction>
        </div>
    );
}
