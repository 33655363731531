import {Navigate} from 'react-router-dom';
import RouteEnum from "../../../constants/Enums/EnumRoutes";
import isRefreshTokenExpired from "../../../utils/general/general";

export default function ProtectedRoute({ children }){
    if (isRefreshTokenExpired()) {
        return <Navigate to={RouteEnum.SIGN_IN} replace />;
    }

    return children;
};