import styles from "../ProfileInformation/PersonalPlayerDashboard.module.css";
import React from "react";

export default function ProfileField({fieldData}){
    const [[profile_field_name, profile_field_value]] = Object.entries(fieldData);
    return (
        <div className={styles.profile_field}>
            <span className={styles.profile_field_name}>
                {profile_field_name}:
            </span>
            <span className={styles.profile_field_value}>
                {profile_field_value}
            </span>
        </div>
    )
}