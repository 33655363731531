import Enum from "./EnumTemplate";


export default class ProfileApiEnum extends Enum {
    static GET_PROFILE_FIELDS = "profile/get_fields/"
    static PATCH_AVATAR = "profile/add_avatar/"
    static POST_FRIEND = "friend/create/"
    static PATCH_ACCEPT_FRIENDSHIP = "friend/activate_friendship/"
    static DELETE_DENIED_FRIENDSHIP = "friend/refuse_friendship/"
    static GET_FRIENDSHIP_INVITES = "friend/friendship_invites/"
}