import styles from "./PlayerProfileSummary.module.css";
import React from "react";

import AvatarProfile from "../../GeneralComponents/AvatarProfile/AvatarProfile";
import {useUserContext} from "../../../hooks/useUser";

export default function PlayerProfileSummary(){
    const {user} = useUserContext()

    return (
        <>
            <div className={styles.photo_management}>
                <AvatarProfile avatar={user.avatar}></AvatarProfile>
            </div>
            <div className={styles.profile_management}>
                <div className={styles.profile_field}>{user.username}</div>
                <div className={styles.profile_field}>Rating: {user.rating}</div>
            </div>
        </>
    )
}