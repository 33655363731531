export const errorEmptyField = "The field must be filled in"
export const errorPasswordIdentity = "Passwords do not match"

export const errorCommonValidationText = {
    errorEnglish: "Allowed characters: latin letters, numbers, dashes, underscore",
    errorSpaceText: "The field must not contain spaces"
}

export const errorUsernameValidationText = {
    ...errorCommonValidationText,
    errorLengthText: "The username must be in a range 3-50 character",
    errorFirstCharacterDigitText: "The first character cannot be a digit"
}

export const errorEmailValidationText = {
    ...errorCommonValidationText,
    errorLocalPartLengthText: "Max character length before \"@\" symbol - 51",
    errorDomainPartLengthText: "Max character length after \"@\" symbol - 52",
    errorLengthText: "The email must be in a range 6-60 characters",
    errorCorrectLocalPartEmailText: "Underscore, period, or dash must be followed by a letter or number",
    errorCorrectDomainPartEmailText: "Allowed domain characters: letters, numbers, dashes",
    errorCorrectEndingDomainPartEmailText: "Domain part must have at least two characters after the dot",
    errorCommercialAt: "'Invalid email format",
}

export const errorPasswordValidationText = {
    ...errorCommonValidationText,
    errorLengthText: "The password must be in a range 8-128 characters",
    errorUpperLowerCaseText: "The password must have 1 uppercase and 1 lowercase letter",
    errorDigitText: "The password must have 1 digit",
}