import {
    PATCH_AVATAR_API_URL,
    GET_PROFILE_FIELDS_API_URL,
    POST_FRIEND_API_URL,
    PATCH_ACCEPT_FRIENDSHIP_API_URL, DELETE_DENIED_FRIENDSHIP_API_URL, GET_FRIENDSHIP_INVITES_API_URL
} from "../constants/profile/api";
import axiosInstance from "./axiosTemplate";

export const patchAvatar = (avatarFile) => {
    const formData = new FormData();
    formData.append('photo', avatarFile);

    return axiosInstance.patch(PATCH_AVATAR_API_URL, formData);
}

export const getProfileFields = () => {
    return axiosInstance.get(GET_PROFILE_FIELDS_API_URL)
}

export const addFriend = (data) => {
    return axiosInstance.post(POST_FRIEND_API_URL, data)
}

export const acceptFriendship = (data) => {
    return axiosInstance.patch(PATCH_ACCEPT_FRIENDSHIP_API_URL, data)
}

export const deniedFriendship = (inviter) => {
    const url = `${DELETE_DENIED_FRIENDSHIP_API_URL}${inviter}/`
    return axiosInstance.delete(url)
}

export const getFriendshipInvites = () => {
    return axiosInstance.get(GET_FRIENDSHIP_INVITES_API_URL)
}
