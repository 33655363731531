import styles from "../../assets/Main.module.css";
import React from 'react';
import LogoutPanel from "../../components/GeneralComponents/Logout/LogoutPanel";
import PlayerDashboard from "../../components/MainComponents/PlayerDashboard/PlayerDashboard";
import FriendLeaderStatistic from "../../components/MainComponents/Statistics/FriendLeaderStatistic";
import CommonLeaderStatistic from "../../components/MainComponents/Statistics/CommonLeaderStatistic";

export default function Main() {
    return (
        <div className={styles.main_page}>
            <LogoutPanel></LogoutPanel>
            <PlayerDashboard></PlayerDashboard>

            <div className={styles.statistics}>
                <FriendLeaderStatistic></FriendLeaderStatistic>
                <CommonLeaderStatistic></CommonLeaderStatistic>
            </div>
        </div>
    );
}
