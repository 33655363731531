import {createContext, useContext, useEffect, useState} from "react";
import {getProfileSummaryFields} from "../api/main";

const UserContext = createContext(null)

export const UseUser = ({children}) => {
    const [user, setUser] = useState({})
    useEffect(() => {
        getProfileSummaryFields()
            .then((response) => {
                const data = response.data;
                setUser({
                    username: data.username,
                    rating: data.rating,
                    avatar: data.photo,
                });
            })
    }, []);
    return (
        <UserContext.Provider value={{user}}>
            {children}
        </UserContext.Provider>
    );
}

export const useUserContext = () => {
    return useContext(UserContext);
};