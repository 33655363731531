import { useEffect, useRef } from "react";

const useWebSearchOpponentSocket = (wsUrl, roomList, sendData, onAction) => {
    const socketRefs = useRef([]);

    useEffect(() => {
        const sockets = socketRefs.current;
        roomList.forEach((room, index) => {
            if (socketRefs.current[index]) {
                socketRefs.current[index].close();
            }

            const socket = new WebSocket(`${wsUrl}${room.id}/`)
            socketRefs.current[index] = socket;

            socket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                onAction(data);
            };

            socket.onopen = () => {
                if(Object.keys(sendData).length){
                    socket.send(JSON.stringify({ room: sendData }))
                }
            };

            return () => {
                sockets.forEach((socket) => socket && socket.close());
            };
        })
    }, [wsUrl, roomList, sendData, onAction]);
};

export default useWebSearchOpponentSocket;
