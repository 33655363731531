import styles from "./FriendshipInvitation.module.css"
import React, {useEffect, useState} from "react";
import {acceptFriendship, getFriendshipInvites} from "../../../api/profile";

export default function FriendshipInvitation(){
    const [invites, setInvites] = useState([])

    function updateInvites(inviter){
        const newInvites = invites.filter(invite => invite.inviter !== inviter)
        setInvites(newInvites)
    }
    function handleAgreeFriendship(inviter){
        const data = {"inviter": inviter}
        acceptFriendship(data)
            .then(() => {
                updateInvites(inviter)
            })
    }

    const trFriendshipInvites = invites.map((invite) =>
        <tr key={invite['inviter']}>
            <td>{invite['inviter']}</td>
            <td><div className={styles.agree_invitation_btn}
                     onClick={() => handleAgreeFriendship(invite['inviter'])}>Yes</div></td>
            <td><div className={styles.disagree_invitation_btn}>No</div></td>
        </tr>
    )

    useEffect(() => {
        getFriendshipInvites()
            .then((response) => {
                setInvites(response.data)
            })
    }, []);
    return (
        <div className={styles.game_statistic_card}>
            <table className={styles.game_statistics_table}>
                  <thead>
                      <tr>
                          <th>Inviter</th>
                          <th>Agree</th>
                          <th>Disagree</th>
                      </tr>
                  </thead>
                  <tbody>
                      {trFriendshipInvites}
                  </tbody>
            </table>
        </div>
    )
}