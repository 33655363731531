import {jwtDecode} from "jwt-decode";

export function getPartsUrlElements(){
    const url= window.location.href
    const parts = url.split('/')
    const uid = parts[parts.length - 3]
    const token = parts[parts.length - 2]
    return [uid, token]
}

export const getAuthConfig = (token) => ({
    headers: {
        Authorization: `Bearer ${token}`,
    },
})

export default function isRefreshTokenExpired() {
    const refreshToken = localStorage.getItem('refresh');
    if(!refreshToken){
        return true
    }
    const decodedRefreshToken = jwtDecode(refreshToken);
    const currentTime = Date.now() / 1000;
    return decodedRefreshToken.exp < currentTime
}