import styles from "./PersonalPlayerDashboard.module.css"
import React, {useEffect, useState} from "react";
import defaultAvatar from "../../../static/profile_default.png";
import AvatarProfile from "../../GeneralComponents/AvatarProfile/AvatarProfile";
import ProfileField from "../ProfileField/ProfileField";
import {getProfileFields} from "../../../api/profile";
import AddFriendButton from "../AddFriendButton/AddFriendButton";
export default function PersonalPlayerDashboard(){
    const [profile, setProfile] = useState({
        username: "isLoading",
        rating: "isLoading",
        avatar: defaultAvatar,
        games: "isLoading",
        victories: "isLoading",
        friends: "isLoading"
    });

    useEffect(() => {
        getProfileFields()
            .then((response) => {
                const data = response.data
                setProfile({...data, avatar: data.photo})
            })
    }, []);

    const updateAvatar = (data) => {
        setProfile({
            ...profile,
            avatar: data
        })
    }

    return (
        <div className={styles.profile_card}>
            <AvatarProfile avatar={profile.avatar} updateAvatar={updateAvatar}></AvatarProfile>
            <div className={styles.profile_username}>
                {profile.username}
            </div>
            <div className={styles.profile_achievements}>
                <ProfileField fieldData={{"Rating": profile.rating}}></ProfileField>
                <ProfileField fieldData={{"Victories": profile.victories}}></ProfileField>
                <ProfileField fieldData={{"Games": profile.games}}></ProfileField>
                <ProfileField fieldData={{"Friends": profile.friends}}></ProfileField>
            </div>
            <AddFriendButton></AddFriendButton>
        </div>
    )
}