import {
    DELETE_GAME_ROOM_API_URL,
    GAME_ROOM_API_URL,
    GAME_ROOM_VERSUS_FRIEND_API_URL,
    GET_FRIEND_LEADERS_API_URL
} from "../constants/main/api";
import {GET_LEADERS_API_URL, GET_PROFILE_SUMMARY_FIELDS_API_URL} from "../constants/main/api";
import axiosInstance from "./axiosTemplate";

export const createGameRoom = () => {
    return axiosInstance.post(GAME_ROOM_API_URL, {})
}

export const deleteGameRoom = () => {
    return axiosInstance.delete(DELETE_GAME_ROOM_API_URL)
}

export const getProfileSummaryFields = () => {
    return axiosInstance.get(GET_PROFILE_SUMMARY_FIELDS_API_URL);
}

export const getLeaders = () => {
    return axiosInstance.get(GET_LEADERS_API_URL)
}

export const getFriendLeaders = () => {
    return axiosInstance.get(GET_FRIEND_LEADERS_API_URL)
}

export const createGameRoomVersusFriend = (data) => {
    return axiosInstance.post(GAME_ROOM_VERSUS_FRIEND_API_URL, data)
}